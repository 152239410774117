import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';

import { IPropsHerbalBanner } from './models';

import './HerbalBanner.scss';

const HerbalBanner: FC<IPropsHerbalBanner> = ({ data }) => {
  const {
    properties: {
      sImage,
      sImageAlt,
      content,
      disclaimer,
      productImage,
      productImageAlt,
      productTasteImage,
      productTasteImageAlt,
      backgroundImage,
      backgroundImageAlt,
      titleImage,
      titleImageAlt,
    },
  } = data;

  return (
    <div data-test="HerbalBanner" className="herbal-banner">
      <div className="herbal-banner__background" />
      <GatsbyImage
        objectFit="contain"
        image={sImage}
        alt={sImageAlt}
        className="herbal-banner__s-image"
      />
      <GatsbyImage
        objectFit="contain"
        image={productImage}
        alt={productImageAlt}
        className="herbal-banner__product-image"
      />
      <GatsbyImage
        objectFit="contain"
        image={productTasteImage}
        alt={productTasteImageAlt}
        className="herbal-banner__product-taste-image"
      />
      <GatsbyImage
        objectFit="cover"
        image={backgroundImage}
        alt={backgroundImageAlt}
        className="herbal-banner__background-image"
      />
      <div className="herbal-banner__content">
        <GatsbyImage
          objectFit="cover"
          image={titleImage}
          alt={titleImageAlt}
          className="herbal-banner__title-image"
        />
        <div className="herbal-banner__text">{content}</div>
        <p className="herbal-banner__disclaimer">{disclaimer}</p>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalBanner on THerbalBannerStructure {
    properties {
      backgroundImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 970, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      backgroundImageAlt
      content
      disclaimer
      productImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 665, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      productImageAlt
      productTasteImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 370, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      productTasteImageAlt
      sImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 370, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      sImageAlt
      titleImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 970, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      titleImageAlt
    }
  }
`;

export default HerbalBanner;
