import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import RelatedCarousel from 'components/RelatedCarousel/RelatedCarousel';
import BenefitItem from 'components/HerbalBenefits/BenefitItem';

import useScreenRecognition from 'hooks/useScreenRecognition/useScreenRecognition';

import { IPropsHerbalBenefits } from './models';
import './HerbalBenefits.scss';

const HerbalBenefits: FC<IPropsHerbalBenefits> = ({ data, prevAriaLabel, nextAriaLabel }) => {
  const {
    properties: { items, image, imageAlt },
  } = data;

  const { windowWidth } = useScreenRecognition();

  const itemsToShow = useMemo(() => {
    if (windowWidth) {
      return windowWidth >= 1440 ? 5 : windowWidth >= 768 ? 3.08 : 1.17;
    }

    return 1.17;
  }, [windowWidth]);

  const isShowPagination = useMemo(() => items.length > itemsToShow, [itemsToShow, items]);

  return (
    <div data-test="HerbalBenefits" className="herbal-benefits">
      <GatsbyImage image={image} alt={imageAlt} className="herbal-benefits__image" />
      <RelatedCarousel
        slidesPerView={itemsToShow}
        ariaLabelPrev={prevAriaLabel}
        ariaLabelNext={nextAriaLabel}
        isDesktop={false}
        className="herbal-benefits__carousel"
        isShowNavigation={false}
        isShowPagination={isShowPagination}
        removeSpaceBetween
      >
        {items.map((item) => (
          <BenefitItem key={item.properties.color[0].properties.color[0]} data={item} />
        ))}
      </RelatedCarousel>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalBenefits on THerbalBenefitsStructure {
    properties {
      imageAlt
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 524, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      items {
        ...FragmentHerbalBenefitItem
      }
    }
  }
`;

export default HerbalBenefits;
