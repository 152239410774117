import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';

import RelatedCarousel from 'components/RelatedCarousel/RelatedCarousel';
import ProductCard from 'components/HerbalProducts/ProductCard';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { IPropsHerbalProducts } from './models';
import './HerbalProducts.scss';
import './ShadowHerbalProducts.scss';

const HerbalProducts: FC<IPropsHerbalProducts> = ({ data, prevAriaLabel, nextAriaLabel }) => {
  const {
    properties: { title, subTitle, items },
  } = data;
  const { isDesktop, isTablet } = useScreenRecognition();

  const slidesAmount = useMemo(
    () => (isDesktop ? 3 : isTablet ? 2 : 1),
    [items, isDesktop, isTablet]
  );

  return (
    <div data-test="HerbalProducts" className="herbal-products">
      <h4 className="herbal-products__title">{title}</h4>
      <h5 className="herbal-products__sub-title">{subTitle}</h5>
      <RelatedCarousel
        slidesPerView={slidesAmount}
        ariaLabelPrev={prevAriaLabel}
        ariaLabelNext={nextAriaLabel}
        isDesktop={false}
        isShowNavigation={items.length > slidesAmount}
        isShowPagination={items.length > slidesAmount}
        className="herbal-products__carousel"
      >
        {items.map((card) => (
          <ProductCard key={card.properties.title} data={card} />
        ))}
      </RelatedCarousel>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalProducts on THerbalProductsStructure {
    properties {
      title
      subTitle
      items {
        ...FragmentHerbalProductCard
      }
    }
  }
`;

export default HerbalProducts;
