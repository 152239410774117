import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';

import { IPropsHerbalProductCard } from './models';
import './ProductCard.scss';
import './ShadowProductCard.scss';

const ProductCard: FC<IPropsHerbalProductCard> = ({ data }) => {
  const {
    properties: {
      image,
      imageAlt,
      title,
      productColor,
      productImage,
      productImageAlt,
      description,
      button,
    },
  } = data;

  return (
    <div data-test="ProductCard" className="herbal-product-card">
      <GatsbyImage
        className="herbal-product-card__image"
        image={image}
        alt={imageAlt}
        objectFit="contain"
      />
      <h5
        className={classNames(
          'herbal-product-card__title',
          `herbal-product-card__title--${productColor[0]}`
        )}
      >
        {title}
      </h5>
      <GatsbyImage
        className="herbal-product-card__product-image"
        image={productImage}
        alt={productImageAlt}
        objectFit="contain"
      />
      <div className="herbal-product-card__description">{description}</div>
      {button.length ? (
        <Button
          ariaLabel={button[0]?.properties?.ariaLabel}
          link={button[0]?.properties?.link?.[0]?.url}
          className={classNames(
            'herbal-product-card__button',
            `herbal-product-card__button--${productColor[0]}`
          )}
        >
          {button[0]?.properties?.label}
        </Button>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalProductCard on THerbalProductsItemStructure {
    properties {
      imageAlt
      title
      description
      productColor
      productImageAlt
      button {
        ...FragmentButton
      }
      productImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 4450, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 420, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default ProductCard;
