import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { IPropsMomentsBlock } from './models';
import './MomentsBlock.scss';

const MomentsBlock: FC<IPropsMomentsBlock> = ({ data }) => {
  const {
    properties: { title, items },
  } = data;

  return (
    <div data-test="MomentsBlock" className="moments-block">
      <DangerouslySetInnerHtml html={title} className="moments-block__title" />
      <div className="moments-block__cards">
        {items.map(({ properties: { title: cardTitle, imageAlt, image } }) => (
          <div key={cardTitle} className="moments-block__card">
            <h4 className="moments-block__card__title">{cardTitle}</h4>
            <div className="moments-block__card__image-wrapper">
              <GatsbyImage
                className="moments-block__card__image"
                image={image}
                alt={imageAlt}
                objectFit="contain"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentMomentsBlock on THerbalMomentsStructure {
    properties {
      title
      items {
        properties {
          imageAlt
          title
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MomentsBlock;
