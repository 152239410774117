import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';

import { IPropsHerbalBenefitItem } from './models';
import './BenefitItem.scss';

const BenefitItem: FC<IPropsHerbalBenefitItem> = ({ data }) => {
  const {
    properties: { color, productFeaturesSecondary, items },
  } = data;

  const {
    properties: { color: cardColor },
  } = color[0];

  const {
    properties: {
      logo: { icon },
    },
  } = productFeaturesSecondary[0];

  return (
    <div
      data-test="BenefitItem"
      className={classNames('benefit-item', `benefit-item--${cardColor[0]}`)}
    >
      <div className="benefit-item__icon-container">
        <div className="benefit-item__icon-wrapper">
          <GatsbyImage image={icon} className="benefit-item__main-icon" />
        </div>
      </div>
      {items.map(({ properties: { text, icon: itemIcon } }) => (
        <div key={text} className="benefit-item__content">
          <GatsbyImage image={itemIcon} className="benefit-item__secondary-icon" />
          <DangerouslySetInnerHtml html={text} className="benefit-item__text" />
        </div>
      ))}
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalBenefitItem on THerbalBenefitsItemsStructure {
    properties {
      productFeaturesSecondary {
        properties {
          logo {
            icon {
              svg {
                content
              }
            }
            iconAlt
          }
        }
      }
      items {
        properties {
          text
          icon {
            svg {
              content
            }
          }
        }
      }
      color {
        properties {
          color
        }
      }
    }
  }
`;

export default BenefitItem;
